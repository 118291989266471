import Tooltip from '@/component/Tooltip';
import { config } from '@/util/config';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { StyledLogo } from './Logo.style';

export function Logo() {
  const value = useMemo(() => {
    if (config.ENV === 'production') return null;
    if (config.ENV === 'preprod') return 'PRÉPROD';
    if (config.ENV === 'development') return 'DEV';
    return config.ENV;
  }, []);

  return (
    <StyledLogo>
      <Link to="/dashboard">
        {value === null ? (
          <img src="/logo-long.png" alt="Childéric logo" />
        ) : (
          <Tooltip title="Vous n'êtes pas en production">
            <span>{value}</span>
          </Tooltip>
        )}
      </Link>
    </StyledLogo>
  );
}
