import { useListOrdersReadyForDelivery } from '@/api/delivery/list-orders-ready-for-delivery';
import EditBtn from '@/component/EditBtn/EditBtn';
import LinkTargetBlank from '@/component/LinkTargetBlank';
import { OrderStatusIcon } from '@/component/StatusIcon/OrderStatusIcon';
import Table from '@/component/Table';
import Tooltip from '@/component/Tooltip';
import { useUserIsChilderic, useUserLang } from '@/hook/user';
import { getDateWeek, getSmallDateString } from '@/util/date';
import { I18nNs } from '@/util/i18n';
import { initials } from '@/util/initials';
import {
  Alert,
  Box,
  Button,
  DialogContent,
  Stack,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell from '@mui/material/TableCell';
import upperFirst from 'lodash/upperFirst';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loader from '../Loader';

type Props = {
  showIfEmpty?: boolean;
};

export function DeliveryOrderReadyMessage({ showIfEmpty = false }: Props) {
  const { data: ready, isLoading } = useListOrdersReadyForDelivery();
  const [open, setOpen] = useState<boolean>(false);

  const { t } = useTranslation(I18nNs.Order);
  const lang = useUserLang();

  const isChilderic = useUserIsChilderic();

  const message = useMemo(() => {
    if (!Array.isArray(ready)) return null;

    const nb = ready.length;

    if (nb === 0) return null;

    if (nb === 1) return `1 commande est prête pour l'expédition.`;
    return `${nb} commandes sont prêtes pour l'expédition.`;
  }, [ready]);

  const getVoucherEndOn = useCallback(
    (d: Date | null) => {
      if (!d) return '-';
      const year = d.getFullYear();
      return `S${getDateWeek(d, lang)}/${year}`;
    },
    [lang],
  );

  if (!isChilderic) return null;

  if (isLoading) {
    return (
      <Alert action={<Loader />} severity="info">
        Recherche des commandes en attente d'expédition...
      </Alert>
    );
  }

  if (showIfEmpty && !message) {
    return <Alert severity="info">Aucune commande n'est en attente d'expédition.</Alert>;
  }

  if (!message) return null;
  if (!Array.isArray(ready)) return null;

  return (
    <Box>
      <Alert
        severity="info"
        action={
          ready.length === 1 ? (
            <Button
              component={Link}
              to={`/order/show/${ready[0].id}`}
              sx={{ mb: 1 }}
              size="small"
              variant="contained"
              className="show-btn"
            >
              Afficher la commande
            </Button>
          ) : (
            <Button sx={{ mb: 1 }} size="small" variant="contained" onClick={() => setOpen(true)}>
              Afficher la liste
            </Button>
          )
        }
      >
        {message}
      </Alert>

      <Dialog maxWidth="lg" fullWidth onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Commandes prêtes</DialogTitle>

        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 40 }} />
                <TableCell>{t('th.date')}</TableCell>
                <TableCell>{t('th.order')}</TableCell>
                <TableCell>{t('th.invoice')}</TableCell>
                <TableCell>{t('th.invoicedAt')}</TableCell>
                <TableCell>{t('th.customer')}</TableCell>
                {isChilderic && <TableCell>{t('th.for')}</TableCell>}
                <TableCell>{t('th.saddle')}</TableCell>
                {isChilderic && <TableCell>{t('th.delivery')}</TableCell>}
                <TableCell>{t('th.by')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {(ready || []).map((order) => (
                <TableRow key={order.id}>
                  <TableCell>
                    <OrderStatusIcon status={order.status} isCredit={order.isCredit} />
                  </TableCell>
                  <TableCell>{upperFirst(getSmallDateString(order.createdAt, lang))}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <LinkTargetBlank
                        to={
                          order.isCredit
                            ? `/order/show/${order.creditOrderId}/credit/show/${order.id}`
                            : `/order/show/${order.id}`
                        }
                        label={order.number}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      {order.invoice ? (
                        <LinkTargetBlank
                          to={
                            order.isCredit
                              ? `/order/show/${order.creditOrderId}/credit/show/${order.id}`
                              : `/order/show/${order.id}/invoice`
                          }
                          label={order.invoice}
                        />
                      ) : null}
                    </Stack>
                  </TableCell>
                  <TableCell>{upperFirst(getSmallDateString(order.invoicedAt, lang))}</TableCell>

                  <TableCell>
                    <LinkTargetBlank
                      to={`/customer/show/${order.customerId}`}
                      label={order?.customerName || '-'}
                    />
                  </TableCell>

                  {isChilderic && <TableCell>{order?.for || '-'}</TableCell>}
                  <TableCell>{order?.firstSaddleName ? order.firstSaddleName : '-'}</TableCell>
                  {isChilderic && <TableCell>{getVoucherEndOn(order.voucherEndOn)}</TableCell>}
                  <TableCell>
                    {order?.createdByName ? (
                      <Tooltip title={order?.createdByName}>
                        <span>{initials(order?.createdByName || '-')}</span>
                      </Tooltip>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    <EditBtn
                      to={
                        order.isCredit
                          ? `/order/show/${order.creditOrderId}/credit/show/${order.id}`
                          : `/order/show/${order.id}`
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
