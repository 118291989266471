import type HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useMemo } from 'react';
import { Tooltip } from '../../Tooltip/Tooltip';
import { StyledBaseStatusIcon } from './BaseStatusIcon.style';

type Icon = typeof HelpOutlineOutlinedIcon;

type Props = {
  size?: number;
  icon: Icon;
  color: string;
  title: string;
  className?: string;
};

export default function BaseStatusIcon({ size = 24, icon: Icon, color, title, className }: Props) {
  const sx = useMemo(
    () => ({
      fontSize: size * 0.7,
      color: 'white',
    }),
    [size],
  );

  return (
    <Tooltip title={title}>
      <StyledBaseStatusIcon className={className} $size={size} $color={color}>
        <Icon sx={sx} />
      </StyledBaseStatusIcon>
    </Tooltip>
  );
}

export { BaseStatusIcon };
