import {
  DeliveryStatus,
  OrderStatus,
  PaymentStatus,
  PurchaseOrderStatus,
  RepairStatus,
  VoucherStatus,
} from '@/enum';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

type Icon = typeof HelpOutlineOutlinedIcon;

const deliveryStatusIconColorIndex = new Map<DeliveryStatus, [Icon, string]>();
const orderStatusIconColorIndex = new Map<OrderStatus, [Icon, string]>();
const paymentStatusIconColorIndex = new Map<PaymentStatus, [Icon, string]>();
const purchaseOrderStatusIconColorIndex = new Map<PurchaseOrderStatus, [Icon, string]>();
const repairOrderStatusIconColorIndex = new Map<RepairStatus, [Icon, string]>();
const voucherStatusIconColorIndex = new Map<VoucherStatus, [Icon, string]>();

// Delivery
deliveryStatusIconColorIndex.set(DeliveryStatus.Draft, [RocketOutlinedIcon, '#999999']);
deliveryStatusIconColorIndex.set(DeliveryStatus.Progress, [RocketLaunchOutlinedIcon, '#007BFF']);
deliveryStatusIconColorIndex.set(DeliveryStatus.Finish, [DoneAllOutlinedIcon, '#28A745']);

// Order

orderStatusIconColorIndex.set(OrderStatus.Draft, [DraftsOutlinedIcon, '#999999']);
orderStatusIconColorIndex.set(OrderStatus.Quote, [DescriptionOutlinedIcon, '#007BFF']);
orderStatusIconColorIndex.set(OrderStatus.BeingValidated, [HourglassEmptyOutlinedIcon, '#FFC107']);
orderStatusIconColorIndex.set(OrderStatus.Valid, [DoneOutlinedIcon, '#28A745']);
orderStatusIconColorIndex.set(OrderStatus.Production, [ConstructionOutlinedIcon, '#17A2B8']);
orderStatusIconColorIndex.set(OrderStatus.Delivery, [LocalShippingOutlinedIcon, '#28A745']);
orderStatusIconColorIndex.set(OrderStatus.Finish, [DoneAllOutlinedIcon, '#28A745']);
orderStatusIconColorIndex.set(OrderStatus.Incomplete, [WarningOutlinedIcon, '#8A2BE2']);
orderStatusIconColorIndex.set(OrderStatus.Cancelled, [CancelOutlinedIcon, '#DC3545']);

// Payment
paymentStatusIconColorIndex.set(PaymentStatus.Canceled, [CancelOutlinedIcon, '#DC3545']);
paymentStatusIconColorIndex.set(PaymentStatus.Draft, [DraftsOutlinedIcon, '#999999']);
paymentStatusIconColorIndex.set(PaymentStatus.IsPaid, [DoneAllOutlinedIcon, '#28A745']);
paymentStatusIconColorIndex.set(PaymentStatus.Pending, [AccessTimeIcon, '#2196F3']);
paymentStatusIconColorIndex.set(PaymentStatus.ShouldBePaid, [
  HourglassEmptyOutlinedIcon,
  '#007BFF',
]);

// Purchase
purchaseOrderStatusIconColorIndex.set(PurchaseOrderStatus.Draft, [DraftsOutlinedIcon, '#999999']);
purchaseOrderStatusIconColorIndex.set(PurchaseOrderStatus.Sent, [SendOutlinedIcon, '#007BFF']);
purchaseOrderStatusIconColorIndex.set(PurchaseOrderStatus.Confirmed, [DoneOutlinedIcon, '#28A745']);
purchaseOrderStatusIconColorIndex.set(PurchaseOrderStatus.Received, [RuleIcon, '#005792']);
purchaseOrderStatusIconColorIndex.set(PurchaseOrderStatus.Checked, [
  DoneAllOutlinedIcon,
  '#28A745',
]);
purchaseOrderStatusIconColorIndex.set(PurchaseOrderStatus.Archived, [
  ArchiveOutlinedIcon,
  '#6C757D',
]);

// Repair
repairOrderStatusIconColorIndex.set(RepairStatus.Draft, [DraftsOutlinedIcon, '#999999']);
repairOrderStatusIconColorIndex.set(RepairStatus.SentToFrance, [SendOutlinedIcon, '#FFC107']);
repairOrderStatusIconColorIndex.set(RepairStatus.OnRepair, [ConstructionOutlinedIcon, '#17A2B8']);
repairOrderStatusIconColorIndex.set(RepairStatus.SendBack, [DoneAllOutlinedIcon, '#28A745']);
repairOrderStatusIconColorIndex.set(RepairStatus.Archived, [ArchiveOutlinedIcon, '#6C757D']);

// Voucher
voucherStatusIconColorIndex.set(VoucherStatus.Draft, [DraftsOutlinedIcon, '#999999']);
voucherStatusIconColorIndex.set(VoucherStatus.Finish, [DoneAllOutlinedIcon, '#28A745']);
voucherStatusIconColorIndex.set(VoucherStatus.Prod, [ConstructionOutlinedIcon, '#17A2B8']);

// Index by icon and color

export const orderStatusIconIndex = new Map<OrderStatus, Icon>();
export const paymentStatusIconIndex = new Map<PaymentStatus, Icon>();
export const purchaseOrderStatusIconIndex = new Map<PurchaseOrderStatus, Icon>();
export const repairOrderStatusIconIndex = new Map<RepairStatus, Icon>();
export const deliveryStatusIconIndex = new Map<DeliveryStatus, Icon>();
export const voucherStatusIconIndex = new Map<VoucherStatus, Icon>();

export const orderStatusColorIndex = new Map<OrderStatus, string>();
export const paymentStatusColorIndex = new Map<PaymentStatus, string>();
export const purchaseOrderStatusColorIndex = new Map<PurchaseOrderStatus, string>();
export const repairOrderStatusColorIndex = new Map<RepairStatus, string>();
export const deliveryStatusColorIndex = new Map<DeliveryStatus, string>();
export const voucherStatusColorIndex = new Map<VoucherStatus, string>();

// Generate indexes

Array.from(orderStatusIconColorIndex.entries()).forEach(([status, [icon, color]]) => {
  orderStatusIconIndex.set(status, icon);
  orderStatusColorIndex.set(status, color);
});

Array.from(paymentStatusIconColorIndex.entries()).forEach(([status, [icon, color]]) => {
  paymentStatusIconIndex.set(status, icon);
  paymentStatusColorIndex.set(status, color);
});

Array.from(purchaseOrderStatusIconColorIndex.entries()).forEach(([status, [icon, color]]) => {
  purchaseOrderStatusIconIndex.set(status, icon);
  purchaseOrderStatusColorIndex.set(status, color);
});

Array.from(repairOrderStatusIconColorIndex.entries()).forEach(([status, [icon, color]]) => {
  repairOrderStatusIconIndex.set(status, icon);
  repairOrderStatusColorIndex.set(status, color);
});

Array.from(deliveryStatusIconColorIndex.entries()).forEach(([status, [icon, color]]) => {
  deliveryStatusIconIndex.set(status, icon);
  deliveryStatusColorIndex.set(status, color);
});

Array.from(voucherStatusIconColorIndex.entries()).forEach(([status, [icon, color]]) => {
  voucherStatusIconIndex.set(status, icon);
  voucherStatusColorIndex.set(status, color);
});

const defaultIcon: Icon = HelpOutlineOutlinedIcon;
const defaultColor = '#6c757d';

type UseOuptut = {
  Icon: Icon;
  color: string;
};

export const useDeliveryStatusIconAndColor = (status: DeliveryStatus): UseOuptut => {
  const Icon = deliveryStatusIconIndex.get(status) || defaultIcon;
  const color = deliveryStatusColorIndex.get(status) || defaultColor;
  return { Icon, color };
};

export const useOrderStatusIconAndColor = (status: OrderStatus): UseOuptut => {
  const Icon = orderStatusIconIndex.get(status) || defaultIcon;
  const color = orderStatusColorIndex.get(status) || defaultColor;
  return { Icon, color };
};

export const usePaymentStatusIconAndColor = (status: PaymentStatus): UseOuptut => {
  const Icon = paymentStatusIconIndex.get(status) || defaultIcon;
  const color = paymentStatusColorIndex.get(status) || defaultColor;
  return { Icon, color };
};

export const usePurchaseOrderStatusIconAndColor = (status: PurchaseOrderStatus): UseOuptut => {
  const Icon = purchaseOrderStatusIconIndex.get(status) || defaultIcon;
  const color = purchaseOrderStatusColorIndex.get(status) || defaultColor;
  return { Icon, color };
};

export const useRepairOrderStatusIconAndColor = (status: RepairStatus): UseOuptut => {
  const Icon = repairOrderStatusIconIndex.get(status) || defaultIcon;
  const color = repairOrderStatusColorIndex.get(status) || defaultColor;
  return { Icon, color };
};

export const useVoucherStatusIconAndColor = (status: VoucherStatus): UseOuptut => {
  const Icon = voucherStatusIconIndex.get(status) || defaultIcon;
  const color = voucherStatusColorIndex.get(status) || defaultColor;
  return { Icon, color };
};
